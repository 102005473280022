import { convertDateToBackendFormat } from "../shared/utils";

type ExcelDate = number;
type DateString = string;
type Nullable<T> = T | null;
type DataItem = { reporting_quarter?: string; transaction_date?: string };
import { FUND_REPORT_QUARTER_KEY, COMPANY_REPORT_QUARTER_KEY, FUND_ENTITY_REPORT_QUARTER } from "../shared/constants";

function excelDateToJSDate(serial: ExcelDate): Date {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date = new Date(utc_value * 1000);
  return date;
}

function convertExcelDate(value: Nullable<ExcelDate | DateString>): Nullable<string> {
  if (value === null || value === "") {
    return null;
  }

  let date: Date;

  // Check if the value is a number (Excel date serial)
  if (!isNaN(parseFloat(value as string)) && isFinite(value as number)) {
    date = excelDateToJSDate(value as ExcelDate);
  } else if (typeof value === "string") {
    // If the value is a string, assume it's a date string
    date = new Date(value);
    if (isNaN(date.getTime())) {
      console.error("Invalid date string:", value);
      return null;
    }
  } else {
    console.error("Invalid input type for convertExcelDate:", typeof value);
    return null;
  }

  // Convert the date to the format that the backend expects
  return convertDateToBackendFormat(date);
}

function normalizeString(input: string): string {
  // Trim and check if the input contains any underscores
  const trimmed = input.trim();
  const hasUnderscores = trimmed.includes("_");

  // Convert camelCase to snake_case if no underscores
  let normalized: string;
  if (!hasUnderscores) {
    normalized = trimmed.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
    normalized = normalized.replace(/\s+/g, "_"); // Replace spaces with underscores
  } else {
    normalized = trimmed.toLowerCase();
  }

  return normalized;
}

function normalizeNoSpaceAndMultipleSpaceString(input: string): string {
  let normalized = input.replace(/\s+/g, " ").trim();

  // Insert a space between lower-case letters and upper-case letters
  normalized = normalized.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Insert a space between groups of upper-case letters followed by a lower-case letter
  normalized = normalized.replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2");

  // Insert a space between letters and digits if they are adjacent
  normalized = normalized.replace(/([a-zA-Z])(\d)/g, "$1 $2");
  normalized = normalized.replace(/(\d)([a-zA-Z])/g, "$1 $2");
  if (normalized.includes("Polo Partners USI")) {
    normalized = normalized.replace("US", "US ");
  }
  return normalized;
}

function normalizeQuarterString(input: string): string {
  // Trim the input to remove any leading or trailing whitespace
  const trimmedInput = input.trim();

  // Normalize quarter strings like "Q42023" to "Q4-2023"
  // Ensure that the quarter string starts with a capital 'Q' followed by a digit 1-4 and then a hyphen and a four-digit year
  const normalizedQuarter = trimmedInput.replace(
    /([Qq][1-4])(\d{4})/g,
    //@ts-ignore
    (match, quarter, year) => `${quarter.toUpperCase()}-${year}`
  );
  return normalizedQuarter.toUpperCase().replace("_", "-");
}

function trim(input: string): string {
  return input.trim();
}

function sortData(data: DataItem[], type: string): DataItem[] {
  if (type === "fund_exposure") {
    return data.sort((a, b) => new Date(b.reporting_quarter!).getTime() - new Date(a.reporting_quarter!).getTime());
  } else {
    return data.sort((a, b) => new Date(b.transaction_date!).getTime() - new Date(a.transaction_date!).getTime());
  }
}

export enum FnParams {
  FUND = "fund",
  FUND_ENTITY_FEES = "fund_entity_fees",
  FUND_EXPOSURE = "fund_exposure",
  INVESTMENT = "investment",
  FUND_ENTITY = "fund_entity",
}

export type FnFundTableTypeArg =
  | FnParams.FUND
  | FnParams.INVESTMENT
  | FnParams.FUND_ENTITY_FEES
  | FnParams.FUND_EXPOSURE;

export function getTableNameByType(type: string) {
  switch (type.toLowerCase()) {
    case FnParams.FUND:
      return FUND_REPORT_QUARTER_KEY;
    case FnParams.INVESTMENT:
      return COMPANY_REPORT_QUARTER_KEY;
    case FnParams.FUND_ENTITY:
      return FUND_ENTITY_REPORT_QUARTER;
    default:
      throw new Error("Invalid entity type. Valid types: 'fund', 'investment', 'fund_entity'");
  }
}
export {
  excelDateToJSDate,
  normalizeString,
  sortData,
  trim,
  normalizeNoSpaceAndMultipleSpaceString,
  normalizeQuarterString,
  convertExcelDate,
};
