//@ts-nocheck

import {
  convertApiDataToExcelCompanyTransactions,
  convertApiDataToExcelFormat,
  convertApiDataToExcelFundEntityFees,
  convertApiDataToExcelFundExposure,
  convertApiDataToExcelFundTransactions,
  convertApiDataToExcelPortfolioStatic,
  convertApiDataToExcelPortfolioActuals,
} from "../shared/services/officeDocument";
import { getData as _getData } from "../shared/utils";

import {
  FUND_TRANSACTIONS_KEY,
  COMPANY_TRANSACTIONS_KEY,
  PORTFOLIO_LEVEL_MONITORING_ACTUALS,
  PORTFOLIO_LEVEL_STATIC,
} from "../shared/constants";
import {
  convertExcelDate,
  trim,
  sortData,
  normalizeNoSpaceAndMultipleSpaceString,
  normalizeString,
  normalizeQuarterString,
  getTableNameByType,
  FnParams,
} from "./utils";

import type { FnFundTableTypeArg } from "./utils";

/**
 * For fund-level data, return a report style table. Options include fund transactions, investment transactions, fees (per fund entity), or exposures.
 * @customfunction GET.FUNDTABLE
 * @helpurl https://www.lantern.ai/wp-content/uploads/2024/03/Lantern-Model-Support.pdf
 * @param {string} type The type of data you want to retrieve (e.g., "fund", "investment", "fund_entity_fees", “fund_exposure”)
 * @param {string} fund  The name of the fund to retrieve data for (e.g., "Polo Partners US I")
 * @param {string} [fundEntity] [optional] The name of the fund entity to retrieve data for (e.g., "Polo Partners US I 2")
 * @param {string} [company] [optional] The name of the portfolio company to retrieve data for. (e.g., "Einstein Telecommunication Services Inc.")
 * @param {string} [reportQuarter] [optional] The quarter to retrieve data for (e.g. “Q4-2023”)
 * @param {string} [txnDate] [optional] The date of the transaction (e.g., "2023-12-29")
 * @param {string} [expTypeDimension] [optional] The type of exposure, as a dimension. Only applicable where type = “fund_exposure” (e.g. “currency exposure”, “sector”)
 * @param {string} [expTypeMeasure] [optional] The exposure type measure.
 * @returns {string[][]} A custom table view of transactions.
 */
export async function fundTable(
  type: string,
  fund: string,
  fundEntity?: string,
  company?: string,
  reportQuarter?: string,
  txnDate?: string,
  expTypeDimension?: string,
  expTypeMeasure?: string
) {
  if (!type || !fund) {
    return [["Missing required parameter, please revisit formula inputs"]];
  }
  try {
    type = normalizeString(type) as FnFundTableTypeArg;
    fund = normalizeNoSpaceAndMultipleSpaceString(trim(fund));
    fundEntity = fundEntity && trim(normalizeNoSpaceAndMultipleSpaceString(fundEntity));
    company = company && trim(company);
    reportQuarter = reportQuarter && normalizeQuarterString(normalizeString(reportQuarter));
    expTypeDimension = expTypeDimension && normalizeNoSpaceAndMultipleSpaceString(trim(expTypeDimension));
    expTypeMeasure = expTypeMeasure && normalizeNoSpaceAndMultipleSpaceString(trim(expTypeMeasure));
    // Convert transaction date to required format

    const convertedTxnDate = convertExcelDate(txnDate);

    const tableName = (() => {
      if (type === FnParams.FUND) return FUND_TRANSACTIONS_KEY;
      if (type === FnParams.INVESTMENT) return COMPANY_TRANSACTIONS_KEY;
      return type;
    })();

    const converterToExcelFormat = (() => {
      if (type === FnParams.FUND) return convertApiDataToExcelFundTransactions;
      if (type === FnParams.INVESTMENT) return convertApiDataToExcelCompanyTransactions;
      if (type === FnParams.FUND_ENTITY_FEES) return convertApiDataToExcelFundEntityFees;
      if (type === FnParams.FUND_EXPOSURE) return convertApiDataToExcelFundExposure;

      return () => {
        return {
          dataRows: [["Invalid type: " + type]],
        };
      };
    })();
    // Fetch data using getData function
    const { items: data } = await _getData({
      tableName,
      fundName: fund,
      fundEntity,
      companyName: company,
      reportingQuarter: reportQuarter,
      transactionDate: convertedTxnDate,
      expTypeDimension,
      expTypeMeasure,
    });

    const sortedData = sortData(data, type);

    if (!sortedData || sortedData.length === 0) {
      const errorMessage = [
        "No data found from the database using the following parameters:",
        `type: ${type}`,
        `fund: ${fund}`,
        `fundEntity: ${fundEntity}`,
        `company: ${company}`,
        `reportQuarter: ${reportQuarter}`,
        `txnDate: ${convertedTxnDate}`,
        `expTypeDimension: ${expTypeDimension}`,
        `expTypeMeasure: ${expTypeMeasure}`,
      ].join("\n");

      return [[errorMessage]];
    }

    // Convert API data to Excel format
    const { dataRows } = converterToExcelFormat(sortedData);
    return dataRows;
  } catch (error) {
    console.log("There was an error in the custom function execution");
    console.log(error);

    // Handle error: return a single cell with error message and parameters
    const errorMessage = [
      "Error occurred while fetching data with the following parameters:",
      `type: ${type}`,
      `fund: ${fund}`,
      `fundEntity: ${fundEntity}`,
      `company: ${company}`,
      `reportQuarter: ${reportQuarter}`,
      `txnDate: ${txnDate}`,
      `expTypeDimension: ${expTypeDimension}`,
      `expTypeMeasure: ${expTypeMeasure}`,
    ].join("\n");

    return [[errorMessage]];
  }
}

/**
 * For fund-level data, return a single quarterly metric about either the fund, a fund entity or an investment. Arguments:
 * @customfunction GET.FUNDMETRIC
 * @helpurl https://www.lantern.ai/wp-content/uploads/2024/03/Lantern-Model-Support.pdf
 * @param {string} type The entity type (e.g., "fund", "investment", "fund_entity")
 * @param {string} fund The name of the fund to retrieve data for (e.g., "Polo Partners US I")
 * @param {string} reportQuarter The quarter to retrieve data for (e.g. “Q4-2023”)
 * @param {string} metric The metric to retrieve (e.g., "nav" or “total_remaining_commitment”)
 * @param {string} [fundEntity] [optional] The name of the fund entity to retrieve data for (e.g., "Polo Partners US I 2")
 * @param {string} [company] [optional] The name of the portfolio company to retrieve data for. (e.g., "Einstein Telecommunication Services Inc.")
 * @returns {string[][]} A single metric value.
 */
export async function fundMetric(
  type: string,
  fund: string,
  reportQuarter: string,
  metric: string,
  fundEntity?: string,
  company?: string
) {
  if (!type || !fund || !reportQuarter || !metric) {
    return [["Missing required parameter, please revisit formula inputs"]];
  }
  try {
    type = normalizeString(type);
    metric = metric && normalizeString(metric);
    fund = normalizeNoSpaceAndMultipleSpaceString(trim(fund));
    fundEntity = fundEntity && normalizeNoSpaceAndMultipleSpaceString(trim(fundEntity));
    company = company && trim(company);
    reportQuarter = reportQuarter && normalizeQuarterString(trim(reportQuarter));

    const tableName = getTableNameByType(type);

    const { items: data } = await _getData({
      tableName,
      fundName: fund,
      fundEntity,
      companyName: company,
      reportingQuarter: reportQuarter,
      metric,
    });

    if (data.length === 0) {
      return [["No data found for " + metric + " with the given parameters."]];
    }
    const getLatestSingleMetricBasedOnReportingQuarter = (data: any[], metric: string) => {
      const hasReportingQuarter = data.some((item) => item.reporting_quarter);

      if (hasReportingQuarter) {
        // Sort data by reporting_quarter in descending order to get the latest
        data.sort((a, b) => new Date(b.reporting_quarter).getTime() - new Date(a.reporting_quarter).getTime());
      }

      const latestData = data[0];

      return [{ [metric]: latestData[metric] }];
    };

    const { dataRows } = convertApiDataToExcelFormat(getLatestSingleMetricBasedOnReportingQuarter(data, metric));

    return dataRows;
  } catch (error) {
    console.error("Error in LTN.GET.FUNDMETRIC:", error);
    return [["Error retrieving metric " + metric + ": " + error.message]];
  }
}

/**
 * For portfolio-level data, return a single metric.
 * @customfunction GET.PORTFOLIOMETRIC
 * @helpurl https://www.lantern.ai/wp-content/uploads/2024/03/Lantern-Model-Support.pdf
 * @param {string} type The type of portfolio metric (e.g., "actuals", "static")
 * @param {string} company The name of the portfolio company to retrieve data for (e.g., "Einstein Telecommunication Services Inc.")
 * @param {string} metric The metric to retrieve (e.g., "Gross Margin" or “Revenue”) If type = static, this should be the property_name
 * @param {string} [reportDate] [optional] The report date to retrieve data for (e.g. “2023-01-01”) This defaults to the most recent report date and is not applicable to type = static
 * @returns {string[][]} A single metric value.
 */
export async function portfolioMetric(type: string, company: string, metric: string, reportDate?: string) {
  if (!type || !company || !metric) {
    return [["Missing required parameter, please revisit formula inputs"]];
  }
  try {
    type = normalizeString(type);
    company = trim(company);
    metric = trim(metric);
    reportDate = reportDate && convertExcelDate(reportDate);

    const tableName = (() => {
      if (type === "static") return PORTFOLIO_LEVEL_STATIC;
      if (type === "actuals") return PORTFOLIO_LEVEL_MONITORING_ACTUALS;
      return type;
    })();

    const metricArgs = {};

    if (tableName === PORTFOLIO_LEVEL_MONITORING_ACTUALS) {
      metricArgs["metricName"] = metric;
      metricArgs["metric"] = "metric_value";
    } else if (tableName === PORTFOLIO_LEVEL_STATIC) {
      metricArgs["metric"] = "property_value";
      metricArgs["propertyName"] = metric;
    }

    const { items: data } = await _getData({
      tableName,
      companyName: company,
      reportDate,
      ...metricArgs,
    });

    if (data.length === 0) {
      return [["No data found for " + metric + " with the given parameters."]];
    }
    const getLatestSingleMetricBasedOnReportDate = () => {
      // Should process with this method if there is report date in the returned data
      const latestData = data.reduce((latest, current) => {
        if (!latest.report_date) return current;
        if (!current.report_date) return latest;

        const latestDate = new Date(latest.report_date);
        const currentDate = new Date(current.report_date);
        return currentDate > latestDate ? current : latest;
      });

      return [{ [metric]: latestData[metric] }];
    };

    const { dataRows } = convertApiDataToExcelFormat(getLatestSingleMetricBasedOnReportDate());
    return dataRows;
  } catch (error: any) {
    console.error("Error in LTN.GET.PORTFOLIOMETRIC:", error);
    return [["Error retrieving metric " + metric + ": " + error.message]];
  }
}

/**
 * For portfolio-level data, return a report style table. Options include actuals or static.
 * @customfunction GET.PORTFOLIOTABLE
 * @helpurl https://www.lantern.ai/wp-content/uploads/2024/03/Lantern-Model-Support.pdf
 * @param {string} type The type of portfolio metric (e.g., "actuals", "static")
 * @param {string} company The name of the portfolio company to retrieve data for (e.g., "Einstein Telecommunication Services Inc.")
 * @param {string} [metricGroup] [optional] The metric group to retrieve (e.g., "Cash Flow" or “P&L”) Not applicable to type = static
 * @param {string} [metricType] [optional] The metric type to retrieve (e.g., "Currency" or “Percentage”) Not applicable to type = static
 * @param {string} [metric] [optional] The metric to retrieve (e.g., "Gross Margin" or “Revenue”) If type = static, this should be the property_name
 * @param {string} [reportDate] [optional] The report date to retrieve data for (e.g. “2023-01-01”) Defaults to the most recent report date, not applicable to type = static
 * @returns {string[][]} A custom table view of portfolio-level metrics.
 */
export async function portfolioTable(
  type: string,
  company: string,
  metricGroup?: string,
  metricType?: string,
  metric?: string,
  reportDate?: string
) {
  if (!type || !company) {
    return [["Missing required parameter, please revisit formula inputs"]];
  }

  try {
    type = normalizeString(type);
    company = trim(company);
    metricGroup = metricGroup && normalizeString(metricGroup);
    metricType = metricType && normalizeString(metricType);
    metric = metric && normalizeString(metric);
    reportDate = reportDate && convertExcelDate(reportDate);

    const tableName = (() => {
      if (type === "static") return PORTFOLIO_LEVEL_STATIC;
      if (type === "actuals") return PORTFOLIO_LEVEL_MONITORING_ACTUALS;
      return type;
    })();

    const converterToExcelFormat = (() => {
      if (type === "actuals") return convertApiDataToExcelPortfolioActuals;
      if (type === "static") return convertApiDataToExcelPortfolioStatic;

      return () => {
        return {
          dataRows: [["Invalid type: " + type]],
        };
      };
    })();

    const { items: data } = await _getData({
      tableName,
      companyName: company,
      metricGroup,
      metricType,
      metricName: metric,
      reportDate,
    });

    if (data.length === 0) {
      return [["No data found for the given parameters."]];
    }

    const { dataRows } = converterToExcelFormat(data);

    return dataRows;
  } catch (error: any) {
    console.error("Error in LTN.GET.PORTFOLIOTABLE:", error);
    return [["Error retrieving data: " + error.message]];
  }
}

CustomFunctions.associate("GET.FUNDTABLE", fundTable);
CustomFunctions.associate("GET.FUNDMETRIC", fundMetric);
CustomFunctions.associate("GET.PORTFOLIOMETRIC", portfolioMetric);
CustomFunctions.associate("GET.PORTFOLIOTABLE", portfolioTable);